.sidebar{
    overflow-y: scroll;
    height: 100%;
    padding: 10px ;
    
     
}
.create-space{
    display: flex;
    justify-content: start;
    background-color: #E4E6E6;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 10px;
    font-size: 13px;
    font-weight: 400;
    white-space: nowrap;
    
}
.channel-list{
    padding-top: 20px;
    padding-left: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
    
}

.channel-name{
    font-size: 13px;
    font-weight: 400;
    display: flex;
    gap: 4px;
    cursor: pointer;
    
}
.channel-name:hover{
    background-color: #E4E6E6;
}
.channel-name img{
    width: 28px;
    height: 28px;
    border-radius: 5px;
}
.channel-name p{
    white-space: wrap;
}