
.mainPage {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    background-image: url("https://quora-clone-coral.vercel.app/static/media/background.cd7aa8c58fb4683b2f2a.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.loginPage {
    display: flex;
    flex-direction: column;
    width: 55%;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.loginPageHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -30px;
}

.quora-logo {
    width: 100px;
    height: 40px;
    margin-bottom: 10px;
}
.text {
    all: unset;
    text-align: center;
    color: rgb(83, 83, 83);
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 20px;
}

.loginContent {
    display: flex;
}

.leftSide {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 10px 25px;
    width: 50%;
    border-right: 1px solid #dee0e1;
    margin-bottom: 25px;
}
.termsAndConditions {
    text-align: justify;
    color: grey;
    font-weight: 400;
    font-size: 14px;
}

.condition {
    color: blue;
    cursor: pointer;
}
.leftSideBtn {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.loginBttn,
.signupBttn,
.googleBttn,
.signupBtn {
    background-color: white;
    border: 1px solid #dee0e1;
    font-weight: 400;
    font-size: 16px;
    padding: 8px;
    color: black;
}

.googleBttn,
.signupBtn {
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 11px;
}

.signupBtn {
    text-align: left;
}
.googleLogo {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.emailLogo {
    height: 17px;
    width: 17px;
    margin-right: 8px;
}

.signup_form {
    padding: 10px 40px;
}
.textField {
    background: white;
    width: 100%;
    margin-top: 15px !important;
}

/* button {
    margin: 20px 0 20px 0;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all ease 0.4s;
} */
.log_in {
    text-align: center;
    margin-bottom: 10px;
}
.rightSide {
    padding: 10px 25px;
    width: 50%;
    margin-top: -20px;
}
.rightSideLogin {
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid;
    border-bottom: 1px solid #dee0e1;
    padding: 10px 0;
}
.signup_form,
.login_form {
    padding: 10px 20px;
}
.loginContainer {
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forgotPassword {
    color: grey;
    font-size: 13px;
}
.language {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-top: 1px solid #dee0e1;
    border-bottom: 1px solid #dee0e1;
    padding: 15px;
    font-size: 13px;
}
.languageColor {
    color: rgb(38, 123, 221);
}
.loginFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 10px;
    background-color: #f1f2f2;
}

.footerLogin {
    font-size: 12px;
    cursor: pointer;
}

.footerLogin:hover {
    border-bottom: 1px solid black;

}
.loginBttn:hover,
.signupBttn:hover,
.googleBttn:hover,
.signupBtn:hover {
    background-color: #f1f2f2;
    border: 1px solid black;
}

